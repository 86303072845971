import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    redirect:'/home',
    component: ()=>import('@/views/layout.vue'),
    children:[
      {
        path: '/home',
        name: 'home',
        component: ()=>import('@/views/home.vue')
      },
      {
        path: '/develop',
        name: 'develop',
        component: ()=>import('@/views/develop.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/about.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/contact.vue')
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router

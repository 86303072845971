export default  {
  nav:{
    home: 'Home',
    compony: 'Enterprise development',
    aboutUs:'About Us',
    contactUs:'Contact Us',
  },
  footer:{
    about:{
      title:'About Us',
      content:'Shanghai Hongtan Intelligent Technology Co., Ltd. is located in Lingang New Area, China (Shanghai) Pilot Free Trade Zone, is a national high-tech enterprise and double-soft enterprise focusing on intelligent power distribution products, artificial intelligence technology, Internet of Things technology, new generation information technology, mobile Internet and cloud computing and big data applications, providing intelligent and information products and technical services.'
    },
    contact:{
      title:'contact way',
      subTitle1:'address',
      subTitle2:'phone',
      subTitle3:'email',
      content1:'Room 201-A, Building 2, No. 600, Xinyuan South Road, Lingang New Area, China (Shanghai) Pilot Free Trade Zone',
    },
    time:{
      title:'Working time',
    }
  },
  home:{
    slogan:{
      title1:'independent innovation',
      content1:'In 2019, it was recognized as a high-tech enterprise for the first time.',
      content1b:'In 2022, it will be recognized by the review;',
      title2:'concept innovation',
      content2:'In 2019, it obtained the software enterprise certificate',
      title3:'technological innovation',
      content3:'In 2022, it was awarded the title of Shanghai Innovative Small and Medium-sized Enterprise',
    },
    product:{
      title:'product center',
      p1:'IoT circuit breakers',
      p2:'HTP8 multi-function network power meter',
      p3:'Car charging piles',
      p4:'Precision power distribution',
      p5:'Box-type substation',
      p6:'MNS Intelligent low-voltage switchgear',
      p7:'Low-voltage integrated protection device',
      p8:'Cloud platform software',
    },
    features:{
      title:'Product Features',
      product1:{
        buybtn:'Buy Now',
        btn:'Product Brochure',
        name:'H1-ZN-C IoT Circuit Breaker',
        content1:'It can monitor voltage, current, power, electric energy and temperature in real time;',
        content2:'It has a variety of protection functions such as overvoltage, undervoltage, overload, short circuit, overcurrent, and overtemperature;',
        content3:'It has a variety of control functions such as local manual pusher, local electric control, local locking, remote control, timing control, etc.;',
      },
      product2:{
        name:'H1P power communication module',
        content1:'It has a stable 12V DC output, a maximum current output of 2.5A, and up to 32 miniature circuit breakers to provide power;',
        content2:'It has the function of lightning surge protection, which can be used to conduct and shunt direct and indirect lightning strikes or other instantaneous overvoltages in a very short time to form protection;'
      },
      product3:{
       name:'MNS intelligent low-voltage switchgear',
       content1:'Compact structure, more functional units can be installed in a small space, and five drawers can be assembled in a single cabinet or mixed;', 
       content2:'Flexible assembly, strong versatility, with 25mm as the module of C profile and self-made modular parts fastened connection, to meet the needs of various forms, environments and protection levels;', 
       content3:'High safety performance, a large number of high-strength flame retardant engineering materials are used to effectively strengthen the safety protection performance;',
       content4:'The standard module design is easy to use, suitable for all kinds of cabinet structures and drawer units, easy to install and use, and does not require special complex tools.', 
      },
      product4:{
       name:'HC310 smart power measurement and control terminal', 
       content1:'Using high-performance 32-bit ARM processor and professional embedded operating system to ensure the safe, stable and reliable operation of the product.',
       content2:'The special clock chip, the time power-down operation, and the support of network timing to ensure the accuracy and reliability of the product clock.',
      }
    },
    main:{
      title:'Main Products',
      product1:'MNS intelligent low-voltage switchgear',
      product2:'Low-voltage protection measurement and control device',
      product3:'HTZN-1000 intelligent power distribution cabinet',
      product4:'IoT circuit breakers',
      product5:'H1P power communication module',
      product6:'HC310 smart power measurement and control terminal',
    },
    ezt:{
      title:'EZtCloud Platform',
      content:'EZtCloud is a unified access platform for IoT devices, helping enterprises build personalized IoT applications in a very short period of time, and adapt to changing development needs, which can access various gateways, sensors, actuators, controllers, intelligent hardware, etc., to achieve data collection, remote control, data analysis, alarm notification, and intelligent linkage, which can be applied to multiple industries and scenarios, such as smart communities, smart parks, smart farming, smart agriculture, smart education, smart power, etc.',
      btn:'Click to enter',
    },
    us:{
      title:'About Us',
      content:'Founded in March 2017 with a registered capital of 20,000 yuan, Shanghai Hongtan Intelligent Technology Co., Ltd. is located in Lingang New Area, China (Shanghai) Pilot Free Trade Zone, is a national high-tech enterprise and double-soft enterprise focusing on intelligent power distribution products, artificial intelligence technology, Internet of Things technology, a new generation of information technology, mobile Internet and cloud computing and big data applications, providing intelligent and information products and technical services. At present, it has obtained 18 authorized patents, 22 software copyrights, 8 product certifications, and developed a series of power distribution products and intelligent power distribution system solutions.'
    },
    serve:{
      title:'Service System',
      content1:'Nationwide marketing network, to provide every customer with accurate, timely, a full range of product supply, technical support and business services',
      content2:'Hongtan Intelligence has always been committed to providing excellent and timely customer service',
      content3:'Pre-sales service: provide customers with selection, answer technical questions, and provide system solutions',
      content4:'After-sales service: to provide customers with on-site installation, commissioning training and services',
      content5:'Value-added services: experts provide customers with data analysis, energy efficiency assessment, energy saving consulting and other services'
    },
  },
  development:{
    standard:{
      title:'Standard'
    },
    qualification:{
      title:'Qualification',
      part1:'"High-tech enterprise" qualification',
      part2:'"Software Enterprise" qualification',
      part3:'"Shanghai Specialized and Special New Enterprise" qualification',
      part4:'Qualification of "Specialized and Special New Enterprise in Lingang New Area".',
      part5:'"Shanghai Innovative Small and Medium-sized Enterprise" qualification',
      part6:'"Shanghai Software Core Competitiveness Enterprise (Innovative)" qualification',
      part7:'GB/T19001 quality system and environment, health" qualification',
      part8:'"Intellectual Property Management System" qualification',
    },
    phylogeny:{
      title:'Phylogeny',
      content:'2019 - 2022 won the "Shanghai Software Core Competitiveness Innovative Enterprise" for four years'
    }
  },
  about:{
    ip:{
      title:'Intellectual Property',
      content:'The company always adheres to the technological innovation path of "combining independent research and development with production, education and research", attaches great importance to the construction and training of the research and development team, and strives to improve the intelligent level of power distribution and consumption in China with the goal of reaching the international leading level. At present, a total of 63 intellectual property rights have been applied, including 21 invention patents and 7 authorized patents, 17 utility model applications and 9 authorized patents, 3 appearance patents and 22 software copyrights.'
    },
    promise:{
      title:'Service Commitment',
      part1:'Pre-sale',
      part2:'On-sale',
      part3:'After-sale',
    },
    company:{
      title:'Company Profile',
      content:'Founded in March 2017 with a registered capital of 20,000 yuan, Shanghai Hongtan Intelligent Technology Co., Ltd. is located in Lingang New Area, China (Shanghai) Pilot Free Trade Zone, is a national high-tech enterprise and double-soft enterprise focusing on intelligent power distribution products, artificial intelligence technology, Internet of Things technology, a new generation of information technology, mobile Internet and cloud computing and big data applications, providing intelligent and information products and technical services. At present, it has obtained 18 authorized patents, 22 software copyrights, 8 product certifications, and developed a series of power distribution products and intelligent power distribution system solutions.'
    },
    project:{
      title:'Scientific projects',
      part1:{
        name:'Undertake one "High-tech Industry and Scientific and Technological Innovation Special Project" in the Lingang New Area of China (Shanghai) Pilot Free Trade Zone',
        title:'Project Title: Research and Application of Key Technologies of Intelligent Micro Circuit Breaker',
        content:'In June 2019, the project of "Research and Application of Key Technologies of Intelligent Micro Circuit Breaker" was approved, the project has formed a series of Internet of Things circuit breakers, and has passed the certification of China Quality Certification Center, and currently applies for 9 related invention patents, including 4 authorized inventions, 4 authorized utility model patents, 2 appearance patents, 2 authorized software copyrights, and 4 scientific and technological papers. The products are also widely used in industrial parks, large public buildings, gas stations, wind farms and other fields.'
      },
      part2:{
        name:'Undertake 1 project of "Science and Technology Innovation Action Plan" of Shanghai Municipal Science and Technology Commission',
        title:'Project Title: Research and Application of Key Technologies for Integrated Acquisition and Smart Energy Use of New Energy Efficiency Management',
        content:'In 2021, Hongsandalwood Intelligent Technology approved the R&D project of "Research and Application of Key Technologies for Integrated Collection and Smart Energy Use of New Energy Efficiency Management", which was supported by the 2022 Shanghai Science and Technology SME Technology Innovation Fund, project number: 220H1123000. He applied for 4 invention patents and formed the transformation of achievements. This technology improves the power quality monitoring and governance capabilities of industry and commerce, actively changes the power management mode, helps more key energy-using enterprises to move towards a refined and intelligent management mode, and guides users to use electricity scientifically and economically.'
      }
    }
  },
  connect:{
    title:'Connect Us',
    address:'Address: Room 201-A, Building 2, No. 600, Xinyuan South Road, Lingang New Area, China (Shanghai) Pilot Free Trade Zone',
    tel:'Phone: +86-13564699538',
    email:'Email: 80337761@qq.com'
  }
}
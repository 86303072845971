import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messageZH from './messages/zh'
import messageEN from './messages/en'

console.log(process)
console.log(process.env)

Vue.use(VueI18n)
const locale = process.env.VUE_APP_LANG // default locale 
export default new VueI18n({
  locale,
  messages: {
    'en_US': {
      message: messageEN
    },
    'zh_CN': {
      message: messageZH
    }
  }
})
export default  {
  nav:{
    home: '首页',
    compony: '企业发展',
    aboutUs:'关于我们',
    contactUs:'联系我们',
  },
  footer:{
    about:{
      title:'关于红檀',
      content:'上海红檀智能科技有限公司位于中国（上海）自由贸易试验区临港新片区，是一家专注于智能配用电产品、人工智能技术、物联网技术、新一代信息技术、移动互联网以及围绕云计算和大数据应用，提供智能化、信息化产品和技术服务的国家级高新技术企业、双软企业。'
    },
    contact:{
      title:'联系方式',
      subTitle1:'地址',
      subTitle2:'电话',
      subTitle3:'邮箱',
      content1:'中国（上海）自由贸易试验区临港新片区新元南路600号2幢201-A室',
    },
    time:{
      title:'工作时间',
    }
  },
  home:{
    slogan:{
      title1:'自主创新',
      content1:'2019年首次通过高新技术企业认定，',
      content1b:'2022年通过复审认定；',
      title2:'观念创新',
      content2:'2019年取得软件企业证书',
      title3:'科技创新',
      content3:'2022年获得上海市创新型中小企业称号',
    },
    product:{
      title:'产品中心',
      p1:'物联网断路器',
      p2:'HTP8多功能网络电力仪表',
      p3:'汽车充电桩',
      p4:'机房精密配电',
      p5:'箱式变电站',
      p6:'MNS智能低压成套开关设备',
      p7:'低压综合保护装置',
      p8:'云平台软件',
    },
    features:{
      title:'产品特点',
      product1:{
        buybtn:"前往购买",
        btn:'产品手册',
        name:'H1-ZN-C物联网断路器',
        content1:'可实时监测电压、电流、功率、电能和温度等参量；',
        content2:'具有过压、欠压、过载、短路、过流、和超温等多种保护功能；',
        content3:'具有本地手动推杆、本地电动控制、本地锁定、远程遥控、定时控制等多种控制功能；',
      },
      product2:{
        name:'H1P电源通讯模块',
        content1:'具备稳定的12V直流输出，最大提供2.5A电流输出可多达32个微型断路器提供电源；',
        content2:'具有防雷击浪涌保护功能，可对直接、间接雷击或其它瞬时过压在极短时间内导通分流，形成保护；'
      },
      product3:{
       name:'MNS智能低压成套开关设备',
       content1:'结构紧凑，以较小空间安装较多功能单元，五种抽屉可选择单个柜体单一组装或混合组装；',
       content2:'组装灵活，通用性强，以25mm为模数的C型材及自制模数化零部件紧固连接，满足各种形式、环境和防护等级的需要;', 
       content3:'安全性能高，大量采用高强度阻燃工程材料，有效加强安全防护性能；', 
       content4:'标准模块设计，易用性强，适用各类柜架结构和抽屉单元，安装使用便捷，无需特殊复杂工具。', 
      },
      product4:{
       name:'HC310智慧用电测控终端', 
       content1:'采用高性能32位ARM处理器，专业的嵌入式操作系统保证产品安全、稳定、可靠运行。',
       content2:'专用时钟芯片、时间掉电运行，支持网络对时，保证产品时钟的精确性和可靠性。',
      }
    },
    main:{
      title:'主营产品',
      product1:'MNS智能低压成套开关设备',
      product2:'低压保护测控装置',
      product3:'HTZN-1000智能配电柜',
      product4:'物联网断路器',
      product5:'H1P电源通讯模块',
      product6:'HC310智慧用电测控终端',
    },
    ezt:{
      title:'EZtCloud平台',
      content:'EZtCloud是物联网设备统一接入平台，帮助企业在极短的时间内搭建个性化的物联网应用，并适应不断变化的发展需求，可接入各类网关，传感器、执行器、控制器、智能硬件等，实现数据采集、远程控制，数据分析、告警通知、智能联动，可应用于多行业、多场景，如智慧社区、智慧园区、智慧养殖、智慧农业、智慧教育、智慧电力等。',
      btn:'点击进入',
    },
    us:{
      title:'关于我们',
      content:'上海红檀智能科技有限公司成立于2017年3月，注册资金贰仟万元，位于中国（上海）自由贸易试验区临港新片区，是一家专注于智能配用电产品、人工智能技术、物联网技术，新一代信息技术，移动互联网以及围绕云计算和大数据应用，提供智能化、信息化产品和技术服务的国家级高新技术企业、双软企业。目前获得授权专利18件、软件著作权22项、产品认证8项，开发一系列配电产品以及智能配用电系统解决方案，公司产业布局主要面向在中石油、中石化、新能源、智能化园区、教育等行业，用于实现工业自动化过程数据监测及控制，同时向环保、煤化工、智慧城市等行业拓展，形成完整的物联网产业链布局。'
    },
    serve:{
      title:'服务体系',
      content1:'全国性营销网络，为每一位客户提供准确，及时，全方位的产品供应，技术支持及商务服务',
      content2:'红檀智能始终致力于提供卓越及时的客户服务',
      content3:'售前服务：为客户提供选型，解答技术疑问，提供系统方案',
      content4:'售后服务：为客户提供现场安装，调试培训及服务',
      content5:'增值服务：专家为客户提供数据分析，能效评估，节能咨询等服务'
    }
  },
  development:{
    standard:{
      title:'标准制定'
    },
    qualification:{
      title:'企业资质',
      part1:'“高新技术企业”资质',
      part2:'“软件企业”资质',
      part3:'“上海市专精特新企业”资质',
      part4:'“临港新片区专精特新企业”资质',
      part5:'“上海市创新型中小企业”资质',
      part6:'“上海软件核心竞争力企业（创新型）”资质',
      part7:'GB/T19001质量体系及环境、健康”资质',
      part8:'“知识产权管理体系”资质',
    },
    phylogeny:{
      title:'企业发展',
      content:'2019-2022连续四年获得“上海软件核心竞争力创新型企业”'
    }
  },
  about:{
    ip:{
      title:'知识产权',
      content:'公司始终坚持“自主研发与产学研相结合”的技术创新道路，高度重视研发团队的建设和培养，以达到国际领先水平为研发目标，努力提高我国配用电智能化水平。目前累计申请知识产权63项，其中发明专利21项， 授权7项 ；实用新型申请17项，授权9项；外观专利授权3项；软件著作权22项 。',
    },
    promise:{
      title:'服务承诺',
      part1:'售前',
      part2:'售中',
      part3:'售后',
    },
    company:{
      title:'公司简介',
      content:'上海红檀智能科技有限公司成立于2017年3月，注册资金贰仟万元，位于中国（上海）自由贸易试验区临港新片区，是一家专注于智能配用电产品、人工智能技术、物联网技术，新一代信息技术，移动互联网以及围绕云计算和大数据应用，提供智能化、信息化产品和技术服务的国家级高新技术企业、双软企业。目前获得授权专利18件、软件著作权22项、产品认证8项，开发一系列配电产品以及智能配用电系统解决方案，公司产业布局主要面向在中石油、中石化、新能源、智能化园区、教育等行业，用于实现工业自动化过程数据监测及控制，同时向环保、煤化工、智慧城市等行业拓展，形成完整的物联网产业链布局。'
    },
    project:{
      title:'科研项目',
      part1:{
        name:'承担中国（上海）自由贸易试验区临港新片区“高新产业和科技创新专项项目”1项',
        title:'项目名称：《智能微型断路器关键技术研究及应用》',
        content:'我司高度重视科技创新工作，大力加强技术沉淀和研究储备，2019年6月《智能微型断路器关键技术研究与应用》课题通过立项，该项目已经形成物联网断路器系列产品，并通过了中国质量认证中心认证，目前申请相关发明专利9项，其中授权发明4项，授权实用新型专利4项，外观专利2项，授权软件著作权2项，发表科技论文4篇。产品也广泛应用到产业园区大型公共建筑，加油站，风电场等领域。'
      },
      part2:{
        name:'承担上海市科学技术委员会“科技创新行动计划”课题1项',
        title:'项目名称：《新型能效管理一体化采集与智慧用能关键技术研究及应用》',
        content:'2021年红檀智能科技立项《新型能效管理一体化采集与智慧用能关键技术研究及应用》研发项目，获得了上海市2022年科技型中小企业技术创新资金支持，项目编号： 220H1123000。申请发明专利4项，形成了成果转化。该技术提高了工商业的电能质量监测和治理能力，主动变革用电管理模式，帮助更多重点用能企业单位走向精细化、智能化的管理模式，引导用户科学、经济用电用能。'
      }
    }
  },
  connect:{
    title:'联系我们',
    address:'地址：中国（上海）自由贸易试验区临港新片区新元南路600号2幢201-A室',
    tel:'电话：+86-13564699538',
    email:'邮箱：80337761@qq.com'
  }
}